// Constants
import { VueRouter } from '@/Constants/VueRouter'

// Export Routes
export const ModuleRoutes = [
	{
		path: VueRouter.Views.Modules.INTERNAL_CPANEL.PATH,
		name: VueRouter.Views.Modules.INTERNAL_CPANEL.NAME,
		component: () => import('@/Views/Modules/10/InternalCPanel/template.vue')
	},
	{
		path: VueRouter.Views.Modules.EXTERNAL_CPANEL.PATH,
		name: VueRouter.Views.Modules.EXTERNAL_CPANEL.NAME,
		component: () => import('@/Views/Modules/10/ExternalCPanel/template.vue')
	},
	{
		path: VueRouter.Views.Modules.OPERATORS_MANAGER.PATH,
		name: VueRouter.Views.Modules.OPERATORS_MANAGER.NAME,
		component: () => import('@/Views/Modules/10/OperatorsManager/template.vue')
	},
	{
		path: VueRouter.Views.Modules.ZONES_MANAGER.PATH,
		name: VueRouter.Views.Modules.ZONES_MANAGER.NAME,
		component: () => import('@/Views/Modules/10/ZonesManager/template.vue')
	},
	{
		path: VueRouter.Views.Modules.ADMIN_EXTERNAL_USERS.PATH,
		name: VueRouter.Views.Modules.ADMIN_EXTERNAL_USERS.NAME,
		component: () => import('@/Views/Modules/20/ExternalUsers/template.vue')
	},
	{
		path: VueRouter.Views.Modules.ADMIN_INTERNAL_USERS.PATH,
		name: VueRouter.Views.Modules.ADMIN_INTERNAL_USERS.NAME,
		component: () => import('@/Views/Modules/20/InternalUsers/template.vue')
	},
	{
		path: VueRouter.Views.Modules.ADMIN_STORAGES.PATH,
		name: VueRouter.Views.Modules.ADMIN_STORAGES.NAME,
		component: () => import('@/Views/Modules/20/Storages/template.vue')
	},
	{
		path: VueRouter.Views.Modules.ADMIN_SETTINGS.PATH,
		name: VueRouter.Views.Modules.ADMIN_SETTINGS.NAME,
		component: () => import('@/Views/Modules/20/Settings/template.vue')
	},
	{
		path: VueRouter.Views.Modules.SERVICES_CPANEL.PATH,
		name: VueRouter.Views.Modules.SERVICES_CPANEL.NAME,
		component: () => import('@/Views/Modules/30/Services/template.vue')
	},
	{
		props: true,
		path: VueRouter.Views.Modules.CHECKLISTS_CPANEL.PATH,
		name: VueRouter.Views.Modules.CHECKLISTS_CPANEL.NAME,
		component: () => import('@/Views/Modules/40/CheckLists/template.vue')
	},
	{
		path: VueRouter.Views.Modules.SETUP_CHECKLIST_CPANEL.PATH,
		name: VueRouter.Views.Modules.SETUP_CHECKLIST_CPANEL.NAME,
		component: () => import('@/Views/Modules/40/SetupCheckList/template.vue')
	},
	{
		path: VueRouter.Views.Modules.STATISTICS_CPANEL.PATH,
		name: VueRouter.Views.Modules.STATISTICS_CPANEL.NAME,
		component: () => import('@/Views/Modules/50/Statistics/template.vue')
	}
]