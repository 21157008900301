// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Dependencies
import Axios, { AxiosRequestConfig } from 'axios'

// Store
import Store from '@/Store/Global/Default'

// Class Export
export class AxiosHelper {
	private abortController: AbortController

	public abortRequest() {
		if (this.abortController) {
			DevelopmentTools.printError('[AxiosHelper]', 'abortRequest(): Current Axios Request was cancelled!')
			this.abortController.abort()
			this.abortController = undefined
		}
	}

	public getAuthHeader() {
		const token = Store.getters.getStoredAccessToken as string
		return { 'Authorization': `Bearer ${ token }` }
	}

	public async get(url: string, config: AxiosRequestConfig = {}) {
		try {
			this.abortController = new AbortController()
			const headers  = config?.headers || this.getAuthHeader()
			const response = Axios.get(url, { headers, params: config?.params, signal: this.abortController.signal })
			return response
		}
		catch(error) {
			throw error
		}
	}

	public async patch(url: string, config: AxiosRequestConfig = {}) {
		try {
			this.abortController = new AbortController()
			const headers  = config?.headers || this.getAuthHeader()
			const response = Axios.patch(url, config.data, { headers, signal: this.abortController.signal })
			return response
		}
		catch(error) {
			throw error
		}
	}

	public async post(url: string, config: AxiosRequestConfig = {}) {
		try {
			this.abortController = new AbortController()
			const headers  = config?.headers || this.getAuthHeader()
			const response = Axios.post(url, config.data, { headers, signal: this.abortController.signal })
			return response
		}
		catch(error) {
			throw error
		}
	}
}