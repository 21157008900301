// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Component'
import { Server }    from '@/Constants/Server'

// Helpers
import { AxiosHelper } from '@/Helpers/HTTP/AxiosHelper'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexStoragesModule<object>> {
	state: {
		ahFetchStorages: undefined,
		ahFetchStoragesByIdCompany: undefined,
		ahFetchStoragesForPage: undefined,
		response: undefined,
		storagePages: {},
		storageTotalPages: undefined
	},

	mutations: {
		addStorage: function(state, storage) {
			state.response.push(storage)
		},

		destroyStorages: function(state) {
			state.response = undefined
			state.storagePages = {}
			state.storageTotalPages = undefined
		},

		destroyStoragesForPage: function(state, page) {
			delete state.storagePages[`${ page }`]
		},

		storeAHFetchStorages: function(state, ah) {
			state.ahFetchStorages = ah
		},

		storeAHFetchStoragesByIdCompany: function(state, ah) {
			state.ahFetchStoragesByIdCompany = ah
		},

		storeAHFetchStoragesForPage: function(state, ah) {
			state.ahFetchStoragesForPage = ah
		},

		storeStorages: function(state, response) {
			state.response = response
		},

		storeStoragesForPage: function(state, response) {
			state.storagePages[`${ response.page }`] = response.data
			state.storageTotalPages = response.totalPages
		},

		updateStorage: function(state, storage) {
			VuexTools.updateRegistry(state, storage, 'response', '_idStorage')
		}
	},

	getters: {
		getStoredAHFetchStoragesForPage: function(state) {
			return state.ahFetchStoragesForPage
		},

		getStoredStorageById: (state) => (_idStorage: ObjectId) => {
			return state.response?.find((x: any) => x._idStorage === _idStorage)
		},

		getStoredStorages: function(state) {
			return state.response
		},

		getStoredStoragesForPage: (state) => (page: number) => {
			return state.storagePages[`${ page }`]
		},

		getStoredStoragesTotalPages: function(state) {
			return state.storageTotalPages
		}
	},

	actions: {
		fetchStorages: async function({ commit, state }) {
			try {
				// Cancelar cualquier Petición activa.
				state.ahFetchStorages?.abortRequest()

				// Almacenar referencia de 'AxiosHelper'.
				const axiosHelper = new AxiosHelper()
				commit('storeAHFetchStorages', axiosHelper)

				// Realizar Petición a Servidor.
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const response = await axiosHelper.get(Server.Routes.Storages.GetStorages, { params: { _idAdminCompany } })
				commit('storeStorages', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err?.response || err?.code) }
		},

		fetchStoragesByIdCompany: async function({ commit, state }, params) {
			try {
				// Cancelar cualquier Petición activa.
				state.ahFetchStoragesByIdCompany?.abortRequest()

				// Almacenar referencia de 'AxiosHelper'.
				const axiosHelper = new AxiosHelper()
				commit('storeAHFetchStoragesByIdCompany', axiosHelper)

				// Realizar Petición a Servidor.
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const _params = { _idAdminCompany, _idCompany: params._idCompany, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response = await axiosHelper.get(Server.Routes.Storages.GetStoragesByIdCompany, { params: _params })
				return response.data.body[0]
			}
			catch (err) { DevelopmentTools.printError(err?.response || err?.code) }
		},
		
		fetchStoragesForPage: async function({ commit, state }, params) {
			// Cancelar cualquier Petición activa.
			state.ahFetchStoragesForPage?.abortRequest()

			// Almacenar referencia de 'AxiosHelper'.
			const axiosHelper = new AxiosHelper()
			commit('storeAHFetchStoragesForPage', axiosHelper)

			// Verificar que la data correspondiente a la página ya existe.
			const { _idAdminCompany } = Store.getters.getStoredUser as any
			const responseFromPage    = Store.getters.getStoredStoragesForPage(params.page) as Array<any>
			const totalPages          = Store.getters.getStoredStoragesTotalPages as number
			
			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}
			
			// Realizar Petición a Servidor.
			try {
				const _params = { _idAdminCompany, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response = await axiosHelper.get(Server.Routes.Storages.GetStoragesByPage, { params: _params })
				const storages = response.data.body[0]
				commit('storeStoragesForPage', { data: storages.data, page: params.page, totalPages: storages.totalPages })
				return storages
			}
			catch (err) { DevelopmentTools.printError(err?.response || err?.code) }
		}
	}
}

// Module Interfaces
interface VuexStoragesModule<Document, State = VuexStoragesState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexStoragesMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexStoragesState<Document> {
	ahFetchStorages: AxiosHelper
	ahFetchStoragesByIdCompany: AxiosHelper
	ahFetchStoragesForPage: AxiosHelper
	response: Array<Document>
	storagePages: { [key: string]: Array<Document> }
	storageTotalPages: number
}

interface VuexStoragesMutations<State, Document> extends Vuex.Mutations<State> {
	addStorage: (state: State, storage: Document) => void
	storeAHFetchStorages: (state: State, ah: AxiosHelper) => void
	storeAHFetchStoragesByIdCompany: (state: State, ah: AxiosHelper) => void
	storeAHFetchStoragesForPage: (state: State, ah: AxiosHelper) => void
	storeStorages: (state: State, response: Array<Document>) => void
	updateStorage: (state: State, storage: Document) => void
}