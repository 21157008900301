// Store
import Store from '@/Store/Global/Default'

// Class Export
export class VuexTools {
	static getDefaultActionOptions(idKey: string, userIdKey: string): object {
		const user = Store.getters.getStoredUser
		return { params: { [idKey]: user[userIdKey] } }
	}

	static updateComponentClassAnimation(state: any, componentName: string, bool: boolean, trueClass: string, falseClass: string, delay: number) {
		state[componentName].class = bool ? `${ trueClass }-anim` : `${ falseClass }-anim`
		setTimeout(() => state[componentName].class = bool ? trueClass : falseClass, delay)
	}

	static updateRegistry(state: any, registry: any, stateProperty: string, condProperty: string) {
		let index = 0
		for (const o of state[stateProperty]) {
			if (o[condProperty] === registry[condProperty]) {
				state[stateProperty][index] = registry
				break
			}
			index++
		}
	}

	static watchStoreProperty(moduleName: string, names: Array<string> | string) {
		return {
			[`$store.state.${ moduleName }.response`]: function() {
				if (Array.isArray(names)) for (const f of names) this[f]()
				else this[names]()
			}
		}
	}
}