// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Component'
import { Server }    from '@/Constants/Server'

// Helpers
import { AxiosHelper } from '@/Helpers/HTTP/AxiosHelper'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Models
import { CompanyDTO } from '@/Models/CompanyDTO'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexCompaniesModule<CompanyDTO>> {
	state: {
		ahFetchCompanies: undefined,
		ahFetchCompaniesForPage: undefined,
		companyPages: {},
		companyTotalPages: undefined,
		response: undefined
	},

	mutations: {
		addCompany: function(state, company) {
			state.response.push(company)
		},

		destroyCompanies: function(state) {
			state.response = undefined
			state.companyPages = {}
			state.companyTotalPages = undefined
		},

		destroyCompaniesForPage: function(state, page) {
			delete state.companyPages[`${ page }`]
		},

		storeAHFetchCompanies: function(state, ah) {
			state.ahFetchCompanies = ah
		},

		storeAHFetchCompaniesForPage: function(state, ah) {
			state.ahFetchCompaniesForPage = ah
		},

		storeCompanies: function(state, response) {
			state.response = response
		},

		storeCompaniesForPage: function(state, response) {
			state.companyPages[`${ response.page }`] = response.data
			state.companyTotalPages = response.totalPages
		},

		updateCompany: function(state, company) {
			VuexTools.updateRegistry(state, company, 'response', '_idCompany')
		}
	},

	getters: {
		getStoredAHFetchCompaniesForPage: function(state) {
			return state.ahFetchCompaniesForPage
		},

		getStoredCompanyById: (state) => (_idCompany: ObjectId) => {
			return state.response?.find((x: any) => x._idCompany === _idCompany)
		},

		getStoredCompanies: function(state) {
			return state.response
		},

		getStoredCompaniesForPage: (state) => (page: number) => {
			return state.companyPages[`${ page }`]
		},

		getStoredCompaniesTotalPages: function(state) {
			return state.companyTotalPages
		}
	},

	actions: {
		fetchCompanies: async function({ commit, state }) {
			try {
				// Cancelar cualquier Petición activa.
				state.ahFetchCompanies?.abortRequest()

				// Almacenar referencia de 'AxiosHelper'.
				const axiosHelper = new AxiosHelper()
				commit('storeAHFetchCompanies', axiosHelper)

				// Realizar Petición a Servidor.
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const response = await axiosHelper.get(Server.Routes.Companies.GetCompanies, { params: { _idAdminCompany } })
				commit('storeCompanies', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err?.response || err?.code) }
		},
		
		fetchCompaniesForPage: async function({ commit, state }, params) {
			// Cancelar cualquier Petición activa.
			state.ahFetchCompaniesForPage?.abortRequest()

			// Almacenar referencia de 'AxiosHelper'.
			const axiosHelper = new AxiosHelper()
			commit('storeAHFetchCompaniesForPage', axiosHelper)

			// Verificar que la data correspondiente a la página ya existe.
			const { _idAdminCompany } = Store.getters.getStoredUser as any
			const responseFromPage    = Store.getters.getStoredCompaniesForPage(params.page) as Array<any>
			const totalPages          = Store.getters.getStoredCompaniesTotalPages as number

			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}

			// Realizar Petición a Servidor.
			try {
				const _params   = { _idAdminCompany, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response  = await axiosHelper.get(Server.Routes.Companies.GetCompaniesByPage, { params: _params })
				const companies = response.data.body[0]
				commit('storeCompaniesForPage', { data: companies.data, page: params.page, totalPages: companies.totalPages })
				return companies
			}
			catch (err) { DevelopmentTools.printError(err?.response || err?.code) }
		}
	}
}

// Module Interfaces
interface VuexCompaniesModule<Document, State = VuexCompaniesState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexCompaniesMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexCompaniesState<Document> {
	ahFetchCompanies: AxiosHelper
	ahFetchCompaniesForPage: AxiosHelper
	companyPages: { [key: string]: Array<Document> }
	companyTotalPages: number
	response: Array<Document>
}

interface VuexCompaniesMutations<State, Document> extends Vuex.Mutations<State> {
	addCompany: (state: State, company: Document) => void
	storeAHFetchCompanies: (state: State, ah: AxiosHelper) => void
	storeAHFetchCompaniesForPage: (state: State, ah: AxiosHelper) => void
	storeCompanies: (state: State, response: Array<Document>) => void
	updateCompany: (state: State, company: Document) => void
}