// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Constants
import { Server } from '@/Constants/Server'

// Helpers
import { AxiosHelper } from '@/Helpers/HTTP/AxiosHelper'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexNotificationsModule<object>> {
	state: {
		tempNotifications: [],
		updateAllInProgress: false
	},

	mutations: {
		storeTempNotifications: function(state, response) {
			state.tempNotifications = response
		},

		storeUpdateAllInProgress: function(state, bool) {
			state.updateAllInProgress = bool
		}
	},

	getters: {
		getStoredTempNotifications: function(state) {
			return state.tempNotifications
		},

		getStoredUpdateAllInProgress: function(state) {
			return state.updateAllInProgress
		}
	},

	actions: {
		fetchNotificationsByIdEquipment: async function({ commit }, params) {
			try {
				const axiosHelper = new AxiosHelper
				const response    = await axiosHelper.get(Server.Routes.Notifications.GetNotificationsByIdEquipment, { params })
				commit('storeTempNotifications', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},
		
		fetchNotificationsByIdNotification: async function({ commit }, params) {
			try {
				const axiosHelper = new AxiosHelper
				const response    = await axiosHelper.get(Server.Routes.Notifications.GetNotificationsById, { params })
				commit('storeTempNotifications', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},
		
		fetchNotificationsByIdRegister: async function({ commit }, params) {
			try {
				const axiosHelper = new AxiosHelper
				const response    = await axiosHelper.get(Server.Routes.Notifications.GetNotificationsByIdRegister, { params })
				commit('storeTempNotifications', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},
		
		fetchNotificationsByIdStorage: async function({ commit }, params) {
			try {
				const axiosHelper = new AxiosHelper
				const response    = await axiosHelper.get(Server.Routes.Notifications.GetNotificationsByIdStorage, { params })
				commit('storeTempNotifications', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},
		
		fetchNotificationsByIdSystem: async function({ commit }, params) {
			try {
				const axiosHelper = new AxiosHelper
				const response    = await axiosHelper.get(Server.Routes.Notifications.GetNotificationsByIdSystem, { params })
				commit('storeTempNotifications', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexNotificationsModule<Document, State = VuexNotificationsState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexNotificationsMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexNotificationsState<Document> {
	tempNotifications: Array<Document>
	updateAllInProgress: boolean
}

interface VuexNotificationsMutations<State, Document> extends Vuex.Mutations<State> {
	storeTempNotifications: (state: State, response: Array<Document>) => void
	storeUpdateAllInProgress: (state: State, bool: boolean) => void
}