// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Constants
import { Server } from '@/Constants/Server'

// Helpers
import { AxiosHelper } from '@/Helpers/HTTP/AxiosHelper'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Models
import { ModuleDTO } from '@/Models/ModuleDTO'

// Module Export
export default <VuexSystemsModule<ModuleDTO>> {
	state: {
		response: undefined
	},

	mutations: {
		destroySystems: function(state) {
			state.response = undefined
		},

		storeSystems: function(state, response: Array<any>) {
			for (const s of response) {
				s.display = true
				for (const m of s.modules) {
					m.display = true
					m.indeterminate = { read: false, write: false }
					m.privileges = { read: true, write: true }
				}
			}	
			state.response = response
		}
	},

	getters: {
		getStoredSystems: function(state) {
			return state.response
		}
	},

	actions: {
		fetchSystems: async function({ commit }) {
			try {
				const axiosHelper = new AxiosHelper
				const response    = await axiosHelper.get(Server.Routes.Commons.GetSystems)
				commit('storeSystems', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexSystemsModule<Document, State = VuexSystemsState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexSystemsMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexSystemsState<Document> {
	response: Array<Document>
}

interface VuexSystemsMutations<State, Document> extends Vuex.Mutations<State> {
	storeSystems: (state: State, response: Array<Document>) => void
}