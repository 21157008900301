// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Component'
import { Server }    from '@/Constants/Server'

// Helpers
import { AxiosHelper } from '@/Helpers/HTTP/AxiosHelper'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Models
import { EquipmentDTO } from '@/Models/EquipmentDTO'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexEquipmentsModule<EquipmentDTO>> {
	state: {
		ahFetchEquipments: undefined,
		ahFetchEquipmentsForPage: undefined,
		equipmentPages: {},
		equipmentTotalPages: undefined,
		response: undefined
	},

	mutations: {
		addEquipment: function(state, equipment) {
			state.response.push(equipment)
		},

		destroyEquipments: function(state) {
			state.response = undefined
			state.equipmentPages = {}
			state.equipmentTotalPages = undefined
		},

		destroyEquipmentsForPage: function(state, page) {
			delete state.equipmentPages[`${ page }`]
		},

		storeAHFetchEquipments: function(state, ah) {
			state.ahFetchEquipments = ah
		},

		storeAHFetchEquipmentsForPage: function(state, ah) {
			state.ahFetchEquipmentsForPage = ah
		},

		storeEquipments: function(state, response) {
			state.response = response
		},

		storeEquipmentsForPage: function(state, response) {
			state.equipmentPages[`${ response.page }`] = response.data
			state.equipmentTotalPages = response.totalPages
		},

		updateEquipment: function(state, equipment) {
			VuexTools.updateRegistry(state, equipment, 'response', '_idEquipment')
		}
	},

	getters: {
		getStoredAHFetchEquipmentsForPage: function(state) {
			return state.ahFetchEquipmentsForPage
		},

		getStoredEquipmentById: (state) => (_idEquipment: ObjectId) => {
			return state.response?.find((x: any) => x._idEquipment === _idEquipment)
		},

		getStoredEquipments: function(state) {
			return state.response
		},

		getStoredEquipmentsForPage: (state) => (page: number) => {
			return state.equipmentPages[`${ page }`]
		},

		getStoredEquipmentsTotalPages: function(state) {
			return state.equipmentTotalPages
		}
	},

	actions: {
		fetchEquipments: async function({ commit, state }) {
			try {
				// Cancelar cualquier Petición activa.
				state.ahFetchEquipments?.abortRequest()

				// Almacenar referencia de 'AxiosHelper'.
				const axiosHelper = new AxiosHelper()
				commit('storeAHFetchEquipments', axiosHelper)

				// Realizar Petición a Servidor.
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const response = await axiosHelper.get(Server.Routes.Equipments.GetEquipments, { params: { _idAdminCompany } })
				commit('storeEquipments', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err?.response || err?.code) }
		},

		fetchEquipmentsForPage: async function({ commit, state }, params) {
			// Cancelar cualquier Petición activa.
			state.ahFetchEquipmentsForPage?.abortRequest()

			// Almacenar referencia de 'AxiosHelper'.
			const axiosHelper = new AxiosHelper()
			commit('storeAHFetchEquipmentsForPage', axiosHelper)

			// Verificar que la data correspondiente a la página ya existe.
			const { _idAdminCompany } = Store.getters.getStoredUser as any
			const responseFromPage    = Store.getters.getStoredEquipmentsForPage(params.page) as Array<any>
			const totalPages          = Store.getters.getStoredEquipmentsTotalPages as number

			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}
			
			// Realizar Petición a Servidor.
			try {
				const _params  = { _idAdminCompany, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response = await axiosHelper.get(Server.Routes.Equipments.GetEquipmentsByPage, { params: _params })
				const storages = response.data.body[0]
				commit('storeEquipmentsForPage', { data: storages.data, page: params.page, totalPages: storages.totalPages })
				return storages
			}
			catch (err) { DevelopmentTools.printError(err?.response || err?.code) }
		}
	}
}

// Module Interfaces
interface VuexEquipmentsModule<Document, State = VuexEquipmentsState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexEquipmentsMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexEquipmentsState<Document> {
	ahFetchEquipments: AxiosHelper
	ahFetchEquipmentsForPage: AxiosHelper
	equipmentPages: { [key: string]: Array<Document> }
	equipmentTotalPages: number
	response: Array<Document>
}

interface VuexEquipmentsMutations<State, Document> extends Vuex.Mutations<State> {
	addEquipment: (state: State, equipment: Document) => void
	storeAHFetchEquipments: (state: State, ah: AxiosHelper) => void
	storeAHFetchEquipmentsForPage: (state: State, ah: AxiosHelper) => void
	storeEquipments: (state: State, response: Array<Document>) => void
	updateEquipment: (state: State, equipment: Document) => void
}