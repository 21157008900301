// Constants
import { VueRouter } from '@/Constants/VueRouter'

// Export Routes
export const GlobalRoutes = [
	{
		path: VueRouter.Views.Global.LOGIN.PATH,
		name: VueRouter.Views.Global.LOGIN.NAME,
		component: () => import('@/Views/Global/Login/template.vue')
	},
	{
		path: VueRouter.Views.Global.PASSWORD_RESET.PATH,
		name: VueRouter.Views.Global.PASSWORD_RESET.NAME,
		component: () => import('@/Views/Global/PasswordReset/template.vue')
	},
	{
		path: VueRouter.Views.Global.USER_PROFILE.PATH,
		name: VueRouter.Views.Global.USER_PROFILE.NAME,
		component: () => import('@/Views/Global/UserProfile/template.vue')
	}
]