// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Constants
import { Server } from '@/Constants/Server'

// Helpers
import { AxiosHelper } from '@/Helpers/HTTP/AxiosHelper'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexPermissionsModule<object>> {
	state: {
		response: undefined
	},

	mutations: {
		destroyPermissions: function(state) {
			state.response = undefined
		},
		
		storePermissions: function(state, response) {
			state.response = response
		}
	},

	getters: {
		getStoredPermissionById: (state) => (_idPermission: ObjectId) => {
			return state.response?.find((x: any) => x._idPermission === _idPermission)
		},

		getStoredPermissions: function(state) {
			return state.response
		}
	},

	actions: {
		fetchPermissions: async function({ commit }) {
			try {
				const axiosHelper = new AxiosHelper
				const response    = await axiosHelper.get(Server.Routes.Permissions.GetPermissions)
				commit('storePermissions', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexPermissionsModule<Document, State = VuexPermissionsState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexPermissionsMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

export interface VuexPermissionsState<Document> {
	response: Array<Document>
}

interface VuexPermissionsMutations<State, Document> extends Vuex.Mutations<State> {
	storePermissions: (state: State, response: Array<Document>) => void
}