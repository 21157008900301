// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Component'
import { Server }    from '@/Constants/Server'

// Helpers
import { AxiosHelper } from '@/Helpers/HTTP/AxiosHelper'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Models
import { UserDTO } from '@/Models/UserDTO'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexUsersModule<UserDTO>> {
	state: {
		ahFetchExternalUsersForPage: undefined,
		ahFetchInternalUsersForPage: undefined,
		externalUserPages: {},
		externalUserTotalPages: undefined,
		internalUserPages: {},
		internalUserTotalPages: undefined
	},

	mutations: {
		destroyExternalUsers: function(state) {
			state.externalUserPages = {}
			state.externalUserTotalPages = undefined
		},

		destroyExternalUsersForPage: function(state, page) {
			delete state.externalUserPages[`${ page }`]
		},

		destroyInternalUsers: function(state) {
			state.internalUserPages = {}
			state.internalUserTotalPages = undefined
		},

		destroyInternalUsersForPage: function(state, page) {
			delete state.internalUserPages[`${ page }`]
		},

		storeAHFetchUsersForPage: function(state, args) {
			(state as any)[`ahFetch${ args.type }UsersForPage`] = args.ah
		},

		storeExternalUsersForPage: function(state, response) {
			state.externalUserPages[`${ response.page }`] = response.data
			state.externalUserTotalPages = response.totalPages
		},

		storeInternalUsersForPage: function(state, response) {
			state.internalUserPages[`${ response.page }`] = response.data
			state.internalUserTotalPages = response.totalPages
		}
	},

	getters: {
		getStoredAHFetchUsersForPage: (state) => (type: string) => {
			return (state as any)[`ahFetch${ type }UsersForPage`]
		},

		getStoredExternalUsersForPage: (state) => (page: number) => {
			return state.externalUserPages[`${ page }`]
		},

		getStoredExternalUsersTotalPages: function(state) {
			return state.externalUserTotalPages
		},

		getStoredInternalUsersForPage: (state) => (page: number) => {
			return state.internalUserPages[`${ page }`]
		},

		getStoredInternalUsersTotalPages: function(state) {
			return state.internalUserTotalPages
		}
	},

	actions: {
		fetchUsersForPage: async function({ commit, state }, params) {
			// Cancelar cualquier Petición activa.
			(state as any)[`ahFetch${ params.type }UsersForPage`]?.abortRequest()

			// Almacenar referencia de 'AxiosHelper'.
			const axiosHelper = new AxiosHelper()
			commit('storeAHFetchUsersForPage', { ah: axiosHelper, type: params.type })

			// Verificar que la data correspondiente a la página ya existe.
			const { _idAdminCompany } = Store.getters.getStoredUser as any
			const responseFromPage    = Store.getters[`getStored${ params.type }UsersForPage`](params.page) as Array<any>
			const totalPages          = Store.getters[`getStored${ params.type }UsersTotalPages`] as number

			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}

			// Realizar Petición a Servidor.
			try {
				const _params  = { _idAdminCompany, isClient: params.isClient || false, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response = await axiosHelper.get(Server.Routes.Users.GetUsersByPage, { params: _params })
				const users    = response.data.body[0]
				commit(`store${ params.type }UsersForPage`, { data: users.data, page: params.page, totalPages: users.totalPages })
				return users
			}
			catch (err) { DevelopmentTools.printError(err?.response || err?.code) }
		}
	}
}

// Module Interfaces
interface VuexUsersModule<Document, State = VuexUsersState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexUsersMutations<State>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexUsersState<Document> {
	ahFetchExternalUsersForPage: AxiosHelper
	ahFetchInternalUsersForPage: AxiosHelper
	externalUserPages: { [key: string]: Array<Document> }
	externalUserTotalPages: number
	internalUserPages: { [key: string]: Array<Document> }
	internalUserTotalPages: number
}

interface VuexUsersMutations<State> extends Vuex.Mutations<State> {
	storeAHFetchUsersForPage: (state: State, args: { ah: AxiosHelper, type: string }) => void
}