import Vue from 'vue'
Vue.config.productionTip = false

// 00. Globals
import './Plugins/FontAwesome'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// 10. BootstrapVue
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

// 20. Axios
import Axios from 'axios'
import Config from './config'
const { protocol, port, hostname } = Config.axios
Axios.defaults.baseURL = `${ protocol }://${ hostname }:${ port }/`
Axios.defaults.withCredentials = true

// 30. ServiceWorker
import { WorkerTools } from './Classes/Static/WorkerTools'
WorkerTools.registerServiceWorker()

// 40. JsPDF Fonts
import { registerNewJsPDFonts } from './Fonts/JsPDF'
registerNewJsPDFonts()

// 50. Store Dispatches
import Store from './Store/Global/Default'
Store.dispatch('connectToSocketIO')
Store.dispatch('initServiceWorkerChannel')

// 60. Chart.js
import Chart from 'chart.js'
import { LegendExtraHeightSpacing } from './Plugins/ChartJs'
Chart.defaults.global.defaultFontFamily = 'Source Sans Pro'
Chart.plugins.register(require('chartjs-plugin-datalabels'))
Chart.plugins.register(LegendExtraHeightSpacing)

// 99. Vue Instance
import main from './main.vue'
import router from './Router/Default'
new Vue({ router, store: Store, render: h => h(main) }).$mount('#app')