export namespace VueRouter {
	export namespace Views {
		export namespace Global {
			export enum LOGIN {
				NAME = 'ViewGlobalLogin',
				PATH = '/'
			}
	
			export enum PASSWORD_RESET {
				NAME = 'ViewGlobalPasswordReset',
				PATH = '/restablecer/password'
			}
	
			export enum USER_PROFILE {
				NAME = 'ViewGlobalUserProfile',
				PATH = '/usuario/perfil'
			}
		}

		export namespace Modules {
			export enum ADMIN_EXTERNAL_USERS {
				NAME = 'ViewModuleAdminExternalUsers',
				PATH = '/administracion/usuarios/clientes'
			}
	
			export enum ADMIN_INTERNAL_USERS {
				NAME = 'ViewModuleAdminInternalUsers',
				PATH = '/administracion/usuarios/internos'
			}
	
			export enum ADMIN_SETTINGS {
				NAME = 'ViewModuleAdminSettings',
				PATH = '/administracion/configuraciones'
			}

			export enum ADMIN_STORAGES {
				NAME = 'ViewModuleAdminStorages',
				PATH = '/administracion/ubicaciones'
			}

			export enum CHECKLISTS_CPANEL {
				NAME = 'ViewModuleCheckListsCPanel',
				PATH = '/checklist/cpanel'
			}

			export enum EXTERNAL_CPANEL {
				NAME = 'ViewModuleExternalCPanel',
				PATH = '/cpanel/cliente'
			}

			export enum INTERNAL_CPANEL {
				NAME = 'ViewModuleInternalCPanel',
				PATH = '/cpanel/interno'
			}

			export enum OPERATORS_MANAGER {
				NAME = 'ViewModuleOperatorsManager',
				PATH = '/gestion/operarios'
			}

			export enum SERVICES_CPANEL {
				NAME = 'ViewModuleServicesCPanel',
				PATH = '/servicios/cpanel'
			}
	
			export enum SETUP_CHECKLIST_CPANEL {
				NAME = 'ViewModuleSetupCheckListCPanel',
				PATH = '/setup/checklist/cpanel'
			}

			export enum STATISTICS_CPANEL {
				NAME = 'ViewModuleStatisticsCPanel',
				PATH = '/estadisticas/cpanel'
			}

			export enum ZONES_MANAGER {
				NAME = 'ViewModuleZonesManager',
				PATH = '/gestion/ubicaciones/internas'
			}
		}
	}

	export enum ViewModes {
		EXTERNAL,
		INTERNAL
	}
}