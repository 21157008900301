// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Constants
import { Server } from '@/Constants/Server'

// Helpers
import { AxiosHelper } from '@/Helpers/HTTP/AxiosHelper'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexCommunesModule> {
	state: {
		ahFetchCommunes: undefined
	},

	mutations: {
		storeAxiosHelperFetchCommunes: function(state, ah) {
			state.ahFetchCommunes = ah
		}
	},

	actions: {
		fetchCommunes: async function({ commit, state }, params) {
			try {
				// Cancelar cualquier Petición activa.
				state.ahFetchCommunes?.abortRequest()

				// Almacenar referencia de 'AxiosHelper'.
				const axiosHelper = new AxiosHelper()
				commit('storeAxiosHelperFetchCommunes', axiosHelper)

				// Ejecutar Petición.
				const response = await axiosHelper.get(Server.Routes.Communes.GetCommunesByRegion, { params: { region: params.region } })
				const { communes } = response.data.body[0]

				// Retornar Respuesta.
				return communes?.map((x: string) => x.toUpperCase())
			}
			catch (err) { DevelopmentTools.printError(err) }
		}
	}
}

// Module Interfaces
interface VuexCommunesModule<State = VuexCommunesState, Getters = Vuex.Getters<State>> {
	actions: Vuex.Actions<State, Getters>
	state: State
	mutations: VuexCommunesMutations<State>
}

interface VuexCommunesState {
	ahFetchCommunes: AxiosHelper
}

interface VuexCommunesMutations<State> extends Vuex.Mutations<State> {
	storeAxiosHelperFetchCommunes: (state: State, ah: AxiosHelper) => void
}