// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexStoragesModule> {
	actions: {
		initServiceWorkerChannel: async function({ state }) {
			const channel = new BroadcastChannel('sw')
			channel.addEventListener('message', (e) => {
				console.log(e.data)
			})
		}
	}
}

// Module Interfaces
interface VuexStoragesModule {
	actions: Vuex.Actions<{}, {}>
}