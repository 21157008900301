// Constants
import { Documents } from '@/Constants/Documents'
import { VueRouter as VueRouterConstants } from '@/Constants/VueRouter'

// Dependencies
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// Router
import { GlobalRoutes } from '@/Router/Global'
import { ModuleRoutes } from '@/Router/Modules'

// VueRouter Initialization
const Router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes: [...GlobalRoutes, ...ModuleRoutes]
})

function _validateFilteredViews(viewName: string) {
	return [
		VueRouterConstants.Views.Global.PASSWORD_RESET.NAME
	].find((x) => x === viewName)
}

Router.beforeEach(async (to, _, next) => {
	// Filtro para ciertas vistas que no requieren de la validación del AccessToken.
	if (_validateFilteredViews(to.name)) {
		next()
		return
	}

	// Propiedad donde esta almacenado el AcessToken del Store.
	const Store = await import('@/Store/Global/Default')
	const accessToken = Store.default.getters.getStoredAccessToken
	const storedUser  = Store.default.getters.getStoredUser

	// Existen dos tipos principales de rutas, internas y externas.
	// Es importante a cual tipo se debe redireccionar segun el usuario.
	const CPanelRoute = storedUser?._idRole === Documents.Roles.Kreis.CLIENT
		? VueRouterConstants.Views.Modules.EXTERNAL_CPANEL.NAME
		: VueRouterConstants.Views.Modules.INTERNAL_CPANEL.NAME

	// Validar si existe el AccessToken en el resto de vistas, sino será redireccionado al Login.
	if (to.name !== VueRouterConstants.Views.Global.LOGIN.NAME && !accessToken) {
		next({ name: VueRouterConstants.Views.Global.LOGIN.NAME })
	}
	else if (to.name === VueRouterConstants.Views.Global.LOGIN.NAME && accessToken) {
		next({ name: CPanelRoute })
	}
	else {
		next()
	}
})

// VueRouter Export
export default Router